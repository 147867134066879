var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Hover" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeHover) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("To create a collapse which opens on hover use prop")
        ]),
        _c("code", [_vm._v("hover")]),
        _vm._v(" "),
        _c("span", [_vm._v("with")]),
        _c("code", [_vm._v("<app-collapse>")]),
        _c("span", [_vm._v("components.")])
      ]),
      _c(
        "app-collapse",
        { attrs: { hover: "" } },
        [
          _c("app-collapse-item", { attrs: { title: "Accordion Item 1" } }, [
            _vm._v(
              " Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake. "
            )
          ]),
          _c("app-collapse-item", { attrs: { title: "Accordion Item 2" } }, [
            _vm._v(
              " Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake. "
            )
          ]),
          _c("app-collapse-item", { attrs: { title: "Accordion Item 3" } }, [
            _vm._v(
              " Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake. "
            )
          ]),
          _c("app-collapse-item", { attrs: { title: "Accordion Item 4" } }, [
            _vm._v(
              " Pie dragée muffin. Donut cake liquorice marzipan carrot cake topping powder candy. Sugar plum brownie brownie cotton candy. Tootsie roll cotton candy pudding bonbon chocolate cake lemon drops candy. Jelly marshmallow chocolate cake carrot cake bear claw ice cream chocolate. Fruitcake apple pie pudding jelly beans pie candy canes candy canes jelly-o. Tiramisu brownie gummi bears soufflé dessert cake. "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }