var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Bootsrap-vue Collapse" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCollapseBoostrapVue) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _vm._v(
          "Easily toggle visibility of almost any content on your pages. Includes support for making accordions."
        )
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            },
            {
              name: "b-toggle",
              rawName: "v-b-toggle.collapse-1",
              modifiers: { "collapse-1": true }
            }
          ],
          attrs: { variant: "outline-primary" }
        },
        [_vm._v(" Toggle Collapse ")]
      ),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: "collapse-1" } },
        [
          _c(
            "b-card",
            { staticClass: "border mb-0" },
            [
              _c("b-card-text", { staticClass: "card-text" }, [
                _vm._v(" Collapse contents Here ")
              ]),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    },
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-1-inner",
                      modifiers: { "collapse-1-inner": true }
                    }
                  ],
                  attrs: { size: "sm", variant: "outline-primary" }
                },
                [_vm._v(" Toggle Inner Collapse ")]
              ),
              _c(
                "b-collapse",
                { staticClass: "mt-2", attrs: { id: "collapse-1-inner" } },
                [
                  _c("b-card", { staticClass: "border mb-0" }, [
                    _vm._v(" Hello! ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }